body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    text-align: center;
    padding: 20px;
}

.App {
    max-width: 1200px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

    display: grid;
    grid-template-columns: 1fr minmax(auto, 60ch) 1fr;
    grid-template-rows: 2rem auto 2rem;
    gap: 5vh;
    height: 90vh;

    justify-content: center;
    overflow-wrap: break-word;
}

.visible-site-header {
    grid-column: 1 / -1;
    grid-row: 1;
}

.main-content {
    margin-top: 20px;
    grid-column: 2;
    grid-row: 2;
}

.effects-sidebar {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    grid-column: 3;
    grid-row: 2;
}

.image-preview {
    width: 100%;
    height: 60vh;
    margin-top: 20px;
}

canvas {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cropper {
    width: 100%;
    height: 60vh;
    object-fit: contain;
    margin-top: 20px;
}

.image-fit-viewport {
    width: auto;
    height: 100%;
    max-height: calc(100vh - 200px);
    object-fit: contain;
}

.download-button {
    margin-top: 20px;
}

input[type='file'] {
    margin-top: 20px;
}

input[type='range'] {
    width: 100%;
    margin-top: 10px;
}